import React from "react";

export default function Card({ image, title, isButton }) {
  return (
    <div
      className="border-y border-x border-gray-600 cursor-pointer"
      data-aos="fade-up"
    >
      <img
        src={image}
        alt="Project"
        className="w-full h-[450px] object-cover hover:border-8 duration-500 ease-in-out border-gray-300"
      />
      <div className="flex justify-between ">
        <h3 className="p-3 text-xl">{title}</h3>
        {isButton && (
          <button className="border-l border-gray-600 px-8 py-3 text-xl text-[#DE4826] hover:bg-[#DE4826] hover:text-white duration-500 ease-in-out">
            <p>
              <i class="ri-arrow-right-line"></i>
            </p>
          </button>
        )}
      </div>
    </div>
  );
}
