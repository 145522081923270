import React from "react";
import Navbar from "../components/UI/Navbar";

export default function ErrorPage() {
  return (
    <>
      <Navbar />
      <section className="top-0 absolute w-full h-screen flex flex-col justify-center items-center gap-4 ">
        <h1 className="text-3xl md:text-6xl text-red-700 ">
          Something went wrong !
        </h1>
        <p className="text-xl md:text-3xl text-gray-500">
          Could not find this page!
        </p>
      </section>
    </>
  );
}
