import React from "react";
// import ImageSwiper from "../components/UI/ImageSwiper";
import Card from "../components/UI/Card";
import { SmoothScrollHero } from "./SmoothScrollHero";

export default function Home() {
  return (
    <>
      <SmoothScrollHero />
      {/* <section className="mr-0 md:mr-32 mb-10">
        <ImageSwiper />
      </section> */}
      <section className="border-t border-b my-10 border-gray-600">
        <div className="flex justify-center items-center h-[300px] md:h-[450px] mx-8 md:mx-32 border-x border-gray-600">
          <h2 className="text-2xl md:text-5xl w-3/5" data-aos="fade-up">
            We collaborate with clients to create buildings and environments in
            dialogue with culture and place.
          </h2>
        </div>
      </section>
      <section className="px-8 md:px-32 mb-10">
        <div className="grid grid-cols-1 md:grid-cols-2 border-x border-y border-gray-600">
          <div className="p-6 border-gray-600  md:border-r" data-aos="fade-up">
            <img
              src="https://mla-architecture.co.uk/wp-content/uploads/2024/09/4-1-e1727035823504.jpg"
              alt="Home"
            />
          </div>
          <div className="flex flex-col gap-6 p-6 border-gray-600 border-t md:border-none">
            <h2 className="text-2xl md:text-3xl" data-aos="fade-up">
              Welcome to The Archisium Architecture & Development
            </h2>
            <p className="text-gray-500 md:text-xl" data-aos="fade-up">
              At The Archisium Architecture & Development, we are passionate
              about transforming visions into reality. Our award-winning team of
              architectural designers excels in unlocking the hidden potential
              of your property, whether it’s a cherished home or a promising
              development site.
            </p>
            <p className="text-gray-500 md:text-xl" data-aos="fade-up">
              Specializing in creating beautifully tailored homes and innovative
              solutions, we pride ourselves on crafting spaces that reflect your
              unique style and needs. Serving the East Midlands, Home Counties,
              and London, our extensive experience spans residential extensions,
              loft conversions, full property transformations, bespoke new build
              homes and development sites.
            </p>
            <p className="text-gray-500 md:text-xl" data-aos="fade-up">
              If you’re ready to explore the full potential of your property or
              land, let’s start a conversation. We’re here to guide you through
              a journey.
            </p>
            <button
              className="text-left text-xl text-[#DE4826] hover:underline  underline-offset-2 duration-300 ease-in-out"
              data-aos="fade-up"
            >
              LEARN MORE ABOUT US{" "}
              <span>
                <i class="ri-arrow-right-line"></i>
              </span>
            </button>
          </div>
        </div>
      </section>
      <section className="bg-[#F1F1F2] py-16 px-8 md:px-32 border-y border-gray-600">
        <h2 className="text-center text-3xl my-10" data-aos="fade-up">
          Projects
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-20">
          <Card
            image="https://mla-architecture.co.uk/wp-content/uploads/2023/12/SpacePhoto-1-scaled-e1703001978274.jpg"
            title="Dunoon Road"
            isButton={true}
          />
          <Card
            image="https://mla-architecture.co.uk/wp-content/uploads/2024/09/4-1-e1727035823504.jpg"
            title="C S Palace"
            isButton={true}
          />
          <Card
            image="https://mla-architecture.co.uk/wp-content/uploads/2020/03/Wimbledon-15-scaled.jpg"
            title="Villa"
            isButton={true}
          />
        </div>
      </section>
    </>
  );
}
