import React from "react";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import Button from "./Button";

export default function Footer() {
  return (
    <footer>
      <section className="px-8 py-16 md:px-32 mb-10 flex flex-col items-center">
        <h1 data-aos="fade-up" className="text-2xl md:text-7xl uppercase py-5">
          Have a Project in Mind?
        </h1>
        {/* <button
          data-aos="fade-up"
          className="border border-gray-600 py-2 px-3 uppercase text-[#DE4826] duration-500 ease-in-out hover:bg-[#DE4826] hover:text-white"
        >
          <span className="">Submit your breif</span>
          <span className="ml-8 ">
            <i class="ri-arrow-right-line"></i>
          </span>
        </button> */}
        <Button>Submit your breif</Button>
      </section>
      <section className="border-t  border-gray-600  grid grid-cols-1 md:grid-cols-3">
        <div className="py-10 px-7 border-gray-600 border-b flex justify-center items-center">
          <img
            src={logo}
            alt="Logo"
            className="h-28 grayscale hover:grayscale-0 duration-700 ease-in-out cursor-pointer"
            data-aos="fade-up"
          />
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 py-10 px-7 md:border-x border-gray-600 border-b text-lg text-gray-500">
          <ul className="footer-link" data-aos="fade-up">
            <Link to="/about">
              <li>About Us</li>
            </Link>
            <Link to="/project">
              <li>Project</li>
            </Link>
            <Link to="/blog">
              <li>Blog</li>
            </Link>
            <Link to="/contact">
              <li>Contact</li>
            </Link>
          </ul>
          <ul className="footer-link" data-aos="fade-up">
            <li>Architectural Design</li>
            <li>Privacy Policy</li>
          </ul>
          <ul className="footer-link" data-aos="fade-up">
            <li>Terms & Conditions</li>
          </ul>
        </div>
        <div className="flex justify-evenly items-center py-10 px-7 border-gray-600 border-b text-2xl">
          <p
            className="hover:text-[#de4826] duration-500 ease-in-out"
            data-aos="fade-up"
          >
            <i className="ri-facebook-fill"></i>
          </p>
          <p
            className="hover:text-[#de4826] duration-500 ease-in-out"
            data-aos="fade-up"
          >
            <i className="ri-twitter-x-line"></i>
          </p>
          <p
            className="hover:text-[#de4826] duration-500 ease-in-out"
            data-aos="fade-up"
          >
            <i className="ri-instagram-line"></i>
          </p>
          <p
            className="hover:text-[#de4826] duration-500 ease-in-out"
            data-aos="fade-up"
          >
            <i className="ri-linkedin-fill"></i>
          </p>
        </div>
      </section>
      <section className="px-8 md:px-20 py-6 text-gray-500">
        <h3>© 2024 Archisium</h3>
      </section>
    </footer>
  );
}
