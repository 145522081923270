import React, { useState } from "react";
import logo from "../../assets/logo.png";
import { NavLink } from "react-router-dom";
import { AnimatedHamburgerButton } from "./AnimatedHumburgerButtun";
import { HoverImageLinks } from "./HoverIMageLinks";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header>
      <nav className="flex justify-between px-8 md:px-32 py-2 md:py-4 items-center">
        <div className="z-40 relative">
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? "underline" : undefined)}
            end
          >
            <img src={logo} alt="Logo" className="h-20" />
          </NavLink>
        </div>
        {/* <p
          className={`cursor-pointer text-3xl z-40 relative ${
            isOpen && "text-white"
          }`}
          onClick={toggleMenu}
        >
          {isOpen ? (
            <i className="ri-close-large-line"></i>
          ) : (
            <i className="ri-menu-4-line"></i>
          )}
        </p> */}
        <div className="z-40 relative text-3xl">
          <AnimatedHamburgerButton isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
      </nav>
      <div
        className={`fixed top-0 left-0 h-screen w-full bg-[#DE4826] text-white pt-12 transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-700 z-10 ease-in-out`}
      >
        {/* <ul className="flex flex-col px-8 md:px-32 py-4 text-2xl md:text-5xl gap-8">
          <NavLink
            to="/about"
            className={({ isActive }) => (isActive ? "underline" : undefined)}
          >
            <li className="cursor-pointer hover:underline underline-offset-4 duration-500 ease-in-out">
              About Us
            </li>
          </NavLink>
          <NavLink
            to="/project"
            className={({ isActive }) => (isActive ? "underline" : undefined)}
          >
            <li className="cursor-pointer hover:underline underline-offset-4 duration-500 ease-in-out">
              Project
            </li>
          </NavLink>
          <NavLink
            to="/blog"
            className={({ isActive }) => (isActive ? "underline" : undefined)}
          >
            <li className="cursor-pointer hover:underline underline-offset-4 duration-500 ease-in-out">
              Blog
            </li>
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) => (isActive ? "underline" : undefined)}
          >
            <li className="cursor-pointer hover:underline underline-offset-4 duration-500 ease-in-out">
              Contact
            </li>
          </NavLink>
        </ul> */}
        <HoverImageLinks />
      </div>
    </header>
  );
}
