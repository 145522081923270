import React from "react";

import Card from "../components/UI/Card";

export default function Blog() {
  return (
    <>
      <section className="">
        <h1 className="text-4xl md:text-6xl py-7 px-8 md:px-32">Blog</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 border-y border-gray-600 px-8 md:px-32">
          <Card
            title="Embracing the 2024 Changes to Permitted Development Rights"
            image="https://mla-architecture.co.uk/wp-content/uploads/2024/08/wakakaka-scaled-1.jpg"
          />
          <Card
            title="Guide to Option Agreements for Landowners and Homeowners"
            image="https://mla-architecture.co.uk/wp-content/uploads/2024/08/pexels-karolina-grabowska-4491455-scaled.jpg"
          />
          <Card
            title="Case Study: Approval under Class MA Permitted Development"
            image="https://mla-architecture.co.uk/wp-content/uploads/2023/05/1850-CM04.1_Front_Elevation_Top_page-0001-1-scaled.jpg"
          />
        </div>
      </section>
    </>
  );
}
